.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-top: 16px;
}

.logo-wrapper-dark {
  color: #fff !important;
  background: var(--dark-bg-color);
}

.logo-wrapper img {
  height: 100px;
  width: auto;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-box-bg {
  background: #eee;
  border: solid 1px rgba(171, 179, 179, 0.7);
  box-shadow: 0 0.125rem 0.25rem rgba(12, 13, 13, 0.15);
  padding: 32px 0;
}

.login-box-bg-dark {
  color: #fff !important;
  background: #232429 !important;
}

.login-box-bg-dark a {
  color: #fff !important;
}

.login-box {
  background: #fff;
  border: solid 1px rgba(171, 179, 179, 0.7);
  border-left: none;
  border-right: none;
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.login-box-dark {
  color: #fff !important;
  background: var(--dark-bg-color);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form label {
  font-size: 0.7rem;
  font-weight: 600;
  margin: 16px 0 8px;
}
.login-form a {
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--link-blue);
  margin: 16px 0 8px;
}

.password-recover-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-notifi {
  margin-top: 8px;
  color: rgb(202, 52, 97);
}
