@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

:root {
  --dark-bg-color: #333;
  --grey-bg-color: #444;
  --text-color: #fff;
  --shade-blue: #afafaf;
  --blue-color: #3d89d1;
  --blue-selector: #5187e0;
  --link-blue: rgb(19, 113, 177);
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

body {
  font-family: "Open Sans", sans-serif;
}
.relative {
  position: relative;
}

.display-flex {
  display: flex;
  flex-direction: column;
}
.flex {
  flex: 1;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-align {
  text-align: right;
}
