.side-label {
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.side-label img {
  transition: 0.2s;
}

.rotate img {
  transform: rotate(90deg);
}

.side-bar {
  background: var(--dark-bg-color);
  color: var(--text-color);
  width: 256px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.side-bar-logo-wrapper {
  width: 100%;
  padding: 24px;
}

.side-bar-logo-wrapper img {
  height: auto;
  width: 100%;
}

.side-list-wrapper {
  padding: 16px;
  z-index: 100;
}

.side-list-card {
  color: var(--dark-bg-color);
  max-height: 0px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.side-list-inner-wrapper {
  margin-bottom: 48px;
  flex: 1;
  overflow: auto;
}

.side-list {
  margin-bottom: -1px;
}

.expand-side-list {
  max-height: 800px;
}

.side-list p {
  padding: 8px 16px;
  cursor: pointer;
}

.side-list-active {
  font-weight: 600;
  background: var(--blue-selector);
  color: #fff;
}

.side-bar-toggle {
  background: #282828;
  width: 14px;
  cursor: pointer;
}
.side-bar-toggle img {
  width: auto;
  height: 24px;
}

.user-display {
  padding: 8px;
  margin: 20px;
  background: #555;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.user-display button {
  padding: 6px 12px;
}
.user-svg-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(255, 255, 255, 1) 62%
  );
}
