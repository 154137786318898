.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-wrapper-bg {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #33333355;
  z-index: 1200;
}

.create-customer {
  background: #fff;
  position: relative;
  z-index: 1500;
  border-radius: 4px;
  padding: 32px;
}

.create-customer-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.input-wrapper-customer {
  min-width: 250px;
  width: 100%;
}

.input-wrapper-customer label {
  margin-bottom: 8px;
  margin-top: 8px;
  display: block;
}

.username-wrapper {
  grid-column: span 2;
  display: flex;
  align-items: center;
}
.username-wrapper p {
  padding: 0 16px;
  align-self: flex-end;
}

.text-area {
  width: 100%;
  height: 100px;
  padding: 8px;
  border: solid 1px #ccc;
  border-radius: 2px;
  outline: none;
  resize: none;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  margin-top: 8px;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea::placeholder {
  color: #999;
}
