.dark-mode-button {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: 0.3;
  transition: 0.3s;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  color: #bbb !important;
  z-index: 50;
}
.dark-mode-button:hover {
  opacity: 1;
}

.dark {
  background: #333 !important;
  color: #eee !important;
}

.dark2 {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.dark-table-row:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.tab-dark {
  border: none;
}
